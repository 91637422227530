import type { IFallbackScreenProps } from '@ti8m-onboarding/fallback-screens';
import { defineMobileFallbackScreenCustomComponent, defineWebFallbackScreenCustomComponent } from '@ti8m-onboarding/fallback-screens';
import { StylesProvider } from '@ti8m-onboarding/ui';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import ReactDOM from 'react-dom/client';

defineWebFallbackScreenCustomComponent();
defineMobileFallbackScreenCustomComponent();

const idCheckAppProps: IFallbackScreenProps = {
  androidGroupId: 'ch.ti8m.onboarding.suite',
  iOSVendorId: 'ti-m-onboarding-suite',
  iOSAppId: 'id6443938253',
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <StylesProvider>
      <BrowserView>
        <onboarding-web-fallback-screen {...idCheckAppProps} />
      </BrowserView>
      <MobileView>
        <onboarding-mobile-fallback-screen {...idCheckAppProps} />
      </MobileView>
    </StylesProvider>
  </React.StrictMode>,
);
